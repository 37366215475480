















































































































































import { ScheduleBlockRepository } from '../../../shared/src/repositories/entities/scheduleBlocksRepository'
import { TenantRepository } from '../../../shared/src/repositories/entities/tenantRepository'
import QuestionInstanceModel from '../../../shared/src/models/entities/questionInstanceModel'
import TipInstanceModel from '../../../shared/src/models/entities/tipInstanceModel'
import { filterObject, repoParams } from '../../../shared/src/repositories/baseRepository'
import { FileRepository } from '@wellbeingapp/shared/src/repositories/entities/fileRepository'
import ScheduleBlockModel from '@wellbeingapp/shared/src/models/entities/scheduleBlocksModel'
import { RepositoryFactory } from '@wellbeingapp/shared/src/repositories/repositoryFactory'
import DemographicConditions from '../components/dashboard/DemographicConditions.vue'
import QuestionModel from '../../../shared/src/models/entities/questionModel'
import useRepository, { callTypes } from '@wellbeingapp/dashboard/src/composables/useRepository'
import QuestionsTable from '../components/tables/questionsTable.vue'
import TipModel from '../../../shared/src/models/entities/tipModel'
import QuestionsAnswers from '../components/questionsAnswers.vue'
import NoQuestions from '../components/dashboard/NoQuestions.vue'
import BackButton from '../components/buttons/backButton.vue'
import tipsTableVue from '../components/tables/tipsTable.vue'
import usePermissions from '../composables/usePermissions'
import { useRouter } from '../composables/useRouter'
import { defineComponent, ref } from '@vue/composition-api'
import moment from 'moment'
import TargetDemographicModel from '@wellbeingapp/shared/src/models/entities/targetDemographicModel'
import TipForm from '../components/dashboard/forms/TipForm.vue'
import QuestionForm from '../components/dashboard/forms/QuestionForm.vue'
import useGlobalLoading from '../composables/useGlobalLoading'
import CalendarSelector from '../components/dashboard/CalendarSelector.vue'
import ExportScheduleblock from '../components/dashboard/ExportScheduleblock.vue'
import DemographicQuestionRepository from '@wellbeingapp/shared/src/repositories/entities/demographicQuestionRepository'

export default defineComponent({
  name: 'Day',
  components: {
    'back-button': BackButton,
    'questions-table': QuestionsTable,
    'tips-table': tipsTableVue,
    'questions-answers': QuestionsAnswers,
    'no-questions': NoQuestions,
    'demographic-conditions': DemographicConditions,
    'tip-form': TipForm,
    'question-form': QuestionForm,
    'calendar-selector': CalendarSelector,
    'export-scheduleblock': ExportScheduleblock
  },
  setup () {
    moment.locale(navigator.language.split('-')[0])
    const isTipsSidebarVisible = ref<boolean>(false)
    const isQuestionsSidebarVisible = ref<boolean>(false)
    const isAddTipSidebarVisible = ref<boolean>(false)
    const isAddQuestionSidebarVisible = ref<boolean>(false)
    const { route, router } = useRouter()
    const isEdit = ref<boolean>(false)
    const scheduleBlock = ref<ScheduleBlockModel>(new ScheduleBlockModel())
    const scheduleBlockId = ref<string | undefined>('')
    const range = route.value.params.date.split('_')
    const { can } = usePermissions()
    const demographicFeature = ref<boolean>(false)
    const filters = ref<filterObject>()
    const params = ref<repoParams>()
    const displayDateTitle = ref<string>('')
    const activeDate = ref<string>()
    const frequency = ref<number>(1)
    demographicFeature.value = RepositoryFactory.getFeatures().demographic
    const slug = route.value.params.slug

    const startTime = ref<string>('')
    const startDate = ref<string>('')
    const endDate = ref<string>('')

    RepositoryFactory.get(TenantRepository).getTenantStartTime(slug).then((res) => {
      startTime.value = res.schedule_daily_start_time

      const newStartDate = new Date(range[0] + 'T' + startTime.value + 'Z')
      newStartDate.setMinutes(1)
      startDate.value = newStartDate.toISOString()
      endDate.value = (range[1] ? range[1] : range[0]) + 'T' + startTime.value + 'Z'

      setFilters()
      setParams()
      getSelectedScheduleBlock()
    })

    RepositoryFactory.get(ScheduleBlockRepository).activeScheduleBlock(slug).then((res) => {
      activeDate.value = res.date
    }).catch((e: { response }) => {
      activeDate.value = e.response.data.active_date
    })


    const { results: options, doCall } = useRepository(
      DemographicQuestionRepository,
      callTypes.getModelArray,
      { tenant: slug }
    )
    doCall()

    const setFilters = () => {
      filters.value = {
        startDate: { type: 'string', value: startDate.value, filterKey: 'start' },
        endDate: { type: 'string', value: endDate.value, filterKey: 'end' }
      }
    }
    const setParams = () => {
      params.value = { filters: filters.value, tenant: slug }
    }

    const download = (tip: TipModel) => {
      if (tip.image) {
        RepositoryFactory.get(FileRepository).getFileInBase64(tip.image).then((res) => {
          tip.image_file = res
        })
      }
    }

    const getScheduleBlockOnId = () => {
      if (scheduleBlock.value) {
        const { doCall, result, loading } = useRepository(
          ScheduleBlockRepository,
          callTypes.getSingel,
          { id: scheduleBlock.value.id, tenant: slug }
        )
        doCall().then(() => {
          if (result.value) {
            scheduleBlock.value = result.value
            scheduleBlockId.value = result.value.id
            scheduleBlock.value.end = moment(result.value.end).format('YYYY-MM-DD')
            scheduleBlock.value.start_date_next = moment(result.value.start_date_next).format('YYYY-MM-DD')

            if (scheduleBlock.value.tips && scheduleBlock.value.tips.length > 0) {
              for (const tip of scheduleBlock.value.tips) {
                if (tip.tip.image) {
                  download(tip.tip)
                }
              }
            }
          }
        })
      }
    }

    const getSelectedScheduleBlock = () => {
      const { results, doCall } = useRepository<ScheduleBlockModel>(
        ScheduleBlockRepository,
        callTypes.getModelArray,
        params.value
      )
      doCall().then(() => {
        if (results.value[0]) {
          scheduleBlock.value = results.value[0]
          frequency.value = moment.duration(moment(scheduleBlock.value.end).diff(moment(scheduleBlock.value.date))).days()
          if (frequency.value > 1) {
            displayDateTitle.value = moment(scheduleBlock.value.date, 'YYYY-MM-DD').format('dddd D MMMM YYYY') + ' - ' + moment(scheduleBlock.value.end).subtract(1, 'days').format('dddd D MMMM YYYY')
          } else {
            displayDateTitle.value = moment(scheduleBlock.value.date, 'YYYY-MM-DD').format('dddd D MMMM YYYY')
          }
          isEdit.value = true
          getScheduleBlockOnId()
        } else {
          RepositoryFactory.get(ScheduleBlockRepository).activeScheduleBlock(slug).then((res) => {
            scheduleBlock.value = new ScheduleBlockModel()
            const activeDate = moment(res.date)
            if (activeDate >= moment(route.value.params.date)) {
              scheduleBlock.value.state.value = 'HISTORIC'
            } else {
              scheduleBlock.value.state.value = 'FUTURE'
            }
          }).catch((e: { response }) => {
            scheduleBlock.value = new ScheduleBlockModel()
            const activeDate = moment(e.response.data.active_date)
            if (activeDate >= moment(route.value.params.date)) {
              scheduleBlock.value.state.value = 'HISTORIC'
            } else {
              scheduleBlock.value.state.value = 'FUTURE'
              displayDateTitle.value = moment(route.value.params.date, 'YYYY-MM-DD').format('dddd D MMMM YYYY')
            }
          })
        }
      })
    }

    const addTips = (selectedTips: Array<TipModel>) => {
      if (!scheduleBlock?.value?.id) {
        isEdit.value = false
      }
      isTipsSidebarVisible.value = false
      if (!isEdit.value) {
        scheduleBlock.value.tips = []
        scheduleBlock.value.date = route.value.params.date
      }
      selectedTips.forEach((tip: TipModel) => {
        scheduleBlock.value.tips && scheduleBlock.value.tips.push(new TipInstanceModel(tip))
        if (tip.image) {
          download(tip)
        }
      })
      onSubmit()
    }

    const addCreatedTip = (tip: any) => {
      if (scheduleBlock.value.tips === undefined) {
        scheduleBlock.value.tips = []
      }
      scheduleBlock.value.tips.push(tip)
      if (tip.image) {
        download(tip)
      }
      scheduleBlock.value.date = route.value.params.date
      onSubmit()
    }

    const addQuestions = (selectedQuestions: Array<QuestionModel>) => {
      if (!scheduleBlock?.value?.id) {
        isEdit.value = false
      }
      isQuestionsSidebarVisible.value = false
      if (!isEdit.value) {
        scheduleBlock.value.questions = []
        scheduleBlock.value.date = route.value.params.date
      }
      selectedQuestions.forEach((question: QuestionModel) => {
        scheduleBlock.value.questions && scheduleBlock.value.questions.push(new QuestionInstanceModel(question))
      })
      onSubmit()
    }

    const onSubmit = async () : Promise<void> => {
      scheduleBlock.value.end = moment(scheduleBlock.value.end).format('YYYY-MM-DD')
      let repoParams : repoParams = {}
      if (isEdit.value && scheduleBlock.value) {
        repoParams = {
          id: scheduleBlock.value.id,
          model: scheduleBlock.value,
          tenant: slug
        }
      } else {
        repoParams = {
          model: scheduleBlock.value,
          tenant: slug
        }
      }

      const postRepo = useRepository(
        ScheduleBlockRepository,
        isEdit.value ? callTypes.update : callTypes.create,
        repoParams
      )

      useGlobalLoading(postRepo.loading)

      postRepo.doCall().then((success: Boolean) => {
        getSelectedScheduleBlock()
        if (isEdit.value) {
          // toast.send(i18n.t('toast-messages.edited').toString())
        } else {
          // toast.send(i18n.t('toast-messages.created').toString())
        }
      }).catch((e) => {
        // e && toast.send('Opslagen niet gelukt', 'danger')
        getSelectedScheduleBlock()
      })
    }

    const deleteQuestion = (question: QuestionInstanceModel) => {
      if (scheduleBlock.value.questions) {
        const index = scheduleBlock.value.questions.indexOf(question)
        if (index > -1) {
          scheduleBlock.value.questions.splice(index, 1)
        }
        const postRepo = useRepository(
          ScheduleBlockRepository,
          callTypes.update,
          { id: scheduleBlock.value.id, model: scheduleBlock.value, tenant: slug }
        )
        postRepo.doCall()
      }
    }

    const deleteTip = (tip: TipInstanceModel) => {
      if (scheduleBlock.value.tips) {
        const index = scheduleBlock.value.tips.indexOf(tip)
        if (index > -1) {
          scheduleBlock.value.tips.splice(index, 1)
        }
        const postRepo = useRepository(
          ScheduleBlockRepository,
          callTypes.update,
          { id: scheduleBlock.value.id, model: scheduleBlock.value, tenant: slug }
        )
        postRepo.doCall()
      }
    }

    const isScheduleBlockStateFuture = () => {
      if ((scheduleBlock.value.state.value === 'FUTURE')) {
        return true
      } else {
        return false
      }
    }

    const moveQuestion = function (from, to, questions) {
      questions.splice(to, 0, questions.splice(from, 1)[0])
      let repoParams : repoParams = {}
      if (isEdit.value && scheduleBlock.value) {
        repoParams = {
          id: scheduleBlock.value.id,
          model: scheduleBlock.value,
          tenant: slug
        }
      } else {
        repoParams = {
          model: scheduleBlock.value,
          tenant: slug
        }
      }
      const postRepo = useRepository(
        ScheduleBlockRepository,
        isEdit.value ? callTypes.update : callTypes.create,
        repoParams
      )
      postRepo.doCall()
    }

    displayDateTitle.value = moment(range[0], 'YYYY-MM-DD').format('dddd D MMMM YYYY') + (range[1] ? ' - ' + moment(range[1], 'YYYY-MM-DD').format('dddd D MMMM YYYY') : '')

    const patchOptionsQuestionInstance = (targetDemographics: TargetDemographicModel[], questionInstanceId: string) => {
      if (scheduleBlock?.value?.questions) {
        scheduleBlock.value.questions.forEach(questionInstance => {
          if (questionInstance.id === questionInstanceId) {
            questionInstance.target_demographics = targetDemographics
            questionInstance.isUpdating = true
          }
        })
      }

      scheduleBlock.value.end = scheduleBlock?.value?.end?.split('T')[0]
      const params = { id: scheduleBlock.value.id, model: scheduleBlock.value, tenant: slug }
      const { result, doCall } = useRepository(
        ScheduleBlockRepository,
        callTypes.update,
        params
      )
      doCall().then(() => {
        if (scheduleBlock?.value && result?.value) {
          scheduleBlock.value = result.value
        }
      })
    }

    const patchOptionsTipInstance = (targetDemographics: TargetDemographicModel[], tipInstanceId: string) => {
      if (scheduleBlock?.value?.tips) {
        scheduleBlock.value.tips.forEach(tipInstance => {
          if (tipInstance.id === tipInstanceId) {
            tipInstance.target_demographics = targetDemographics
            tipInstance.isUpdating = true
          }
        })
      }

      scheduleBlock.value.end = scheduleBlock?.value?.end?.split('T')[0]
      const params = { id: scheduleBlock.value.id, model: scheduleBlock.value, tenant: slug }
      const { result, doCall } = useRepository(
        ScheduleBlockRepository,
        callTypes.update,
        params
      )
      doCall().then(() => {
        if (scheduleBlock?.value && result?.value) {
          scheduleBlock.value = result.value
        }
      })
    }

    const tipAdded = (event: any) => {
      // addCreatedTip(event)
      if (scheduleBlock.value.tips === undefined) {
        scheduleBlock.value.tips = []
      }
      scheduleBlock.value.date = route.value.params.date
      scheduleBlock.value.tips.push(new TipInstanceModel(event))
      onSubmit()
      isTipsSidebarVisible.value = false
      isAddTipSidebarVisible.value = false
    }

    const questionAdded = (event: any) => {
      if (scheduleBlock.value.questions === undefined) {
        scheduleBlock.value.questions = []
      }
      scheduleBlock.value.date = route.value.params.date
      scheduleBlock.value.questions.push(new QuestionInstanceModel(event))
      onSubmit()
      isQuestionsSidebarVisible.value = false
      isAddQuestionSidebarVisible.value = false
    }

    const selectedDate = (event: string) => {
      if (event !== undefined && event !== route.value.params.date) {
        router.push({ name: 'day', params: { slug: slug, date: event } })
        displayDateTitle.value = moment(event, 'YYYY-MM-DD').format('dddd D MMMM YYYY')

        const newStartDate = new Date(event + 'T' + startTime.value + 'Z')
        newStartDate.setMinutes(1)
        startDate.value = newStartDate.toISOString()
        endDate.value = new Date(event + 'T' + startTime.value + 'Z').toISOString()

        setFilters()
        setParams()
        scheduleBlockId.value = undefined
        getSelectedScheduleBlock()
      }
    }

    const selectedEndDate = (event: any) => {
      if (event !== undefined && event !== scheduleBlock.value.date && event !== moment(scheduleBlock.value.end).format('YYYY-MM-DD')) {
        scheduleBlock.value.end = moment(event).format('YYYY-MM-DD')
        onSubmit()
        getSelectedScheduleBlock()
      }
    }

    const selectorMin = () => moment(scheduleBlock.value.date).add(1, 'days').format('YYYY-MM-DD')
    const selectorMax = () => scheduleBlock.value.start_date_next

    return {
      isAddQuestionSidebarVisible,
      isScheduleBlockStateFuture,
      isQuestionsSidebarVisible,
      isAddTipSidebarVisible,
      isTipsSidebarVisible,
      demographicFeature,
      displayDateTitle,
      scheduleBlockId,
      deleteQuestion,
      scheduleBlock,
      questionAdded,
      patchOptionsQuestionInstance,
      patchOptionsTipInstance,
      selectedDate,
      selectedEndDate,
      addQuestions,
      moveQuestion,
      activeDate,
      deleteTip,
      tipAdded,
      options,
      addTips,
      isEdit,
      can,
      selectorMin,
      selectorMax,
      slug
    }
  }
})
