






















































































import TipModel from '../../../../../shared/src/models/entities/tipModel'
import TextInput, { inputTypes } from '../../inputs/TextInput.vue'
import BaseForm from '../../../components/base-views/baseForm.vue'
import { reactive, defineComponent, ref } from '@vue/composition-api'
import TipRepository from '../../../repositories/TipRepository'
import { useRouter } from '../../../composables/useRouter'
import FileUpload from '../../dashboard/fileUpload.vue'
import MultiSelectInput from '../../inputs/MultiSelectInput.vue'
import TagRepository from '@wellbeingapp/shared/src/repositories/entities/tagRepository'

export default defineComponent({
  name: 'tip-form',
  components: {
    BaseForm,
    TextInput,
    'file-upload': FileUpload,
    'multi-select-input': MultiSelectInput
  },
  props: {
    isFromDayView: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const redirectRoute = ref<string>('tips-overview-screen')
    const { route } = useRouter()
    const tipId = route.value.params.id
    const selectedTags = ref<Array<any>>([])
    const slug = route.value.params.slug
    let defaultValue

    if (!route.value.params.data) {
      defaultValue = TipModel.deserialize({
        id: null,
        label: 'A label',
        link: '',
        video: false,
        read: false,
        img: 'default image',
        content: 'A good tip',
        created_at: 'default created at',
        updated_at: 'deafault updated at',
        read_minutes: 1,
        tags: []
      })
    }

    if (route?.value?.meta?.date) {
      redirectRoute.value = 'day/' + route.value.meta.date
    }

    if (route.value.params.data) {
      defaultValue = route.value.params.data
    }

    const defaultForm = reactive<TipModel>(defaultValue)

    const tipAdded = (event: any) => {
      emit('tipAdded', event)
    }

    return {
      redirectRoute,
      TipRepository,
      defaultForm,
      tipAdded,
      inputTypes,
      TipModel,
      tipId,
      selectedTags,
      TagRepository,
      slug
    }
  }
})
